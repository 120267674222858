import React, { Component } from "react";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import GetAppIcon from "@material-ui/icons/GetApp";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";

import { AbstractInfo } from "./VotingInfo";
import { AbstractGridActionButtons } from "./VotingGridActionButtons";
import { abstractGridOptions } from "./VotingGridOptions";
import { DataTable } from "../Shared/DataTable";

// Configure Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import removeAuthenticatedUser from "../../Store/Actions/removeUser";
import removeEvent from "../../Store/Actions/removeEvent";

import ForceLogOut from "../Shared/ForceLogOut";
// Download to CSV
import { CSVLink } from "react-csv";

const useStyles = (theme) => ({
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  center: {
    textAlign: "center",
  },
  downloadLink: {
    textDecoration: "none",
  },
  downloadButton: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    height: "100%",
    padding: theme.spacing(1),
    margin: "auto",
    "& .MuiButton-textSizeLarge": {
      size: "large",
    },
  },
  downloadButtonContainer: {
    // paddingLeft: '16px',
    // // intentionally using 'sm' below
    // [theme.breakpoints.down('sm')]: {
    //   paddingLeft: '0px',
    //   paddingTop: theme.spacing(2),
    // },
  },
});

class AbstractsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      abstracts: [],
      editing: false,
      currentAbstract: null,
      categories: [],
      abstractsForExport: [],
      loaded: false,
      exhibitors: [],
    };
  }

  async componentDidMount() {
    if (this.props.event.event !== null) {
      await this.getAbstracts();
      await this.getExhibitors();
    }
  }

  async getAbstracts() {
    try {
      await Auth.currentSession().then((data) => {
        axios({
          method: "get",
          url: "/voting/" + this.props.event.event.show_id,
          headers: { idtoken: data.idToken.jwtToken },
        })
          .then((response) => {
            const abstractsForExport = [];
            const categories = [];
            for (let n = 0; n < response.data.length; n++) {
              response.data[n].idAsSequenceNumber =
                response.data[n].abstract_id;

              // response.data[n].consolidatedContacts = [];
              if (response.data[n].contacts.length) {
                response.data[n].presenters = response.data[n].contacts.filter(
                  (contact) =>
                    contact.role.includes("presenter") ||
                    contact.role.includes("Presenter")
                );
                response.data[n].authors = response.data[n].contacts.filter(
                  (contact) =>
                    contact.role.includes("author") ||
                    contact.role.includes("Author")
                );
                response.data[n].submitters = response.data[n].contacts.filter(
                  (contact) =>
                    contact.role.includes("submitter") ||
                    contact.role.includes("Submitter")
                );
                // begin process to merge all single contacts with role into one contact with roles
                let contactIdsUsed = [];
                let cleanContactsHoldingArea = [];
                response.data[n].contacts.forEach((contact, index) => {
                  if (index === 0) {
                    // always push the first, add their role as string to roles array
                    // push them to clean contacts, push contact_id
                    contact.roles = [];
                    contact.roles.push(contact.role);
                    cleanContactsHoldingArea.push(contact);
                    contactIdsUsed.push(contact.contact_id);
                  } else {
                    cleanContactsHoldingArea.forEach((newContact, index2) => {
                      if (newContact.contact_id === contact.contact_id) {
                        // merge role field into roles
                        newContact.roles.push(contact.role);
                      } else if (!contactIdsUsed.includes(contact.contact_id)) {
                        // add their role as string to roles array
                        // contact is new, push them to clean contacts, push contact_id
                        contact.roles = [];
                        contact.roles.push(contact.role);
                        cleanContactsHoldingArea.push(contact);
                        contactIdsUsed.push(contact.contact_id);
                      }
                    });
                  }
                });
                // assign clean contacts to contacts
                response.data[n].contacts = cleanContactsHoldingArea;
                // end merge
              } else {
                response.data[n].presenters = [];
                response.data[n].authors = [];
                response.data[n].submitters = [];
              }

              if (response.data[n].submitters.length) {
                response.data[
                  n
                ].submitterName = `${response.data[n].submitters[0].first_name} ${response.data[n].submitters[0].last_name}`;
              }
              if (response.data[n].presenters.length) {
                response.data[
                  n
                ].presenterName = `${response.data[n].presenters[0].first_name} ${response.data[n].presenters[0].last_name}`;
              }

              if (!categories.includes(response.data[n].topic)) {
                categories.push(response.data[n].topic);
              }

              if (response.data[n].liked) {
                response.data[n].liked = "True";
              } else {
                response.data[n].liked = "False";
              }
              if (!response.data[n].submitters.length) {
                // console.log(response.data[n]);
              }
              // always sort types alphabetically
              function capitalizeFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
              }
              let types;
              if (response.data[n].types?.includes("[")) {
                types = JSON.parse(`${response.data[n].types}`);
                types.sort();
                let newValue = "";
                types.forEach((item, i) => {
                  let newItem = capitalizeFirstLetter(item);
                  if (i === 0) {
                    newValue = newItem;
                  } else {
                    newValue = newValue + `, ${newItem}`;
                  }
                });
                types = newValue;
              } else {
                types = response.data[n].types;
              }
              response.data[n].types = types;
              // console.log(response.data[n]);
              // end sort alphabetically

              let isExhibitor =
                this.props.user.user.account_id !== response.data[n].account_id
                  ? "Yes"
                  : "No";

              let presentersAsString = "";
              if (response.data[n].presenters.length > 0) {
                response.data[n].presenters.forEach((presenter) => {
                  presentersAsString =
                    presentersAsString +
                    `${presenter.first_name} ${
                      presenter.last_name
                    } (${presenter.email.toLowerCase()}),\n`;
                });
              }
              let authorsAsString = "";
              if (response.data[n].authors.length > 0) {
                response.data[n].authors.forEach((author) => {
                  authorsAsString =
                    authorsAsString +
                    `${author.first_name} ${
                      author.last_name
                    } (${author.email.toLowerCase()}),\n`;
                });
              }

              let editedAbstract = {
                SequenceNumber: response.data[n].seq_no,
                SubmittedType: response.data[n].types,
                // SequenceNumber: response.data[n].abstract_id,
                Title: response.data[n].title.replace(/"/g, '""'),
                Site: response.data[n].submitters[0]?.site,
                Topic: response.data[n].topic,
                Modality: response.data[n].submitters[0]?.modality,
                // Country: response.data[n].submitters[0]?.mailing_country,
                IsExhibitor: isExhibitor,
                Submitter: response.data[n].submitterName,
                Presenters: presentersAsString,
                Authors: authorsAsString,
                // JobTitle: response.data[n].submitters[0]?.title,
                Organization: response.data[n].submitters[0]?.department,
                Email: response.data[n].submitters[0]?.email,
                Votes: response.data[n].count,
                Keywords: response.data[n].keywords.replace(/"/g, '""'),
                Notes: response.data[n].description.replace(/"/g, '""'),
                Content: response.data[n].content.replace(/"/g, '""'),
              };

              abstractsForExport.push(editedAbstract);
            }

            response.data = response.data.sort((a, b) => b.count - a.count);

            this.setState({
              categories,
              abstracts: response.data,
              abstractsForExport,
              loaded: true,
            });
          })
          .catch((error) => {
            console.log(error);

            this.setState({
              abstracts: [],
            });
          });
      });
    } catch (error) {
      if (error === "No current user") {
        console.log(error, "log them out");
        try {
          ForceLogOut(
            this.props.removeEvent,
            this.props.removeAuthenticatedUser
          );
        } catch (error) {
          console.log("ForceLogOut", error);
        }
      }
      console.log(error);
    }
  }
  async getExhibitors() {
    await Auth.currentSession().then((data) => {
      axios({
        method: "get",
        url: "/exhibitions/" + this.props.event.event.show_id,
        headers: { idtoken: data.idToken.jwtToken },
      })
        .then((response) => {
          let exhibitors = response.data.data;
          this.setState({
            exhibitors,
          });
        })
        .catch((error) => {
          console.log(error);

          this.setState({
            abstracts: [],
          });
        });
    });
  }

  handleEditingChange(e) {
    this.setState({
      editing: e,
    });

    if (!e) {
      this.getAbstracts();
    }
  }

  setCurrentAbstract(e) {
    this.setState({
      currentAbstract: e,
    });
  }

  handleEditClose() {
    this.setState({
      editing: false,
      currentAbstract: null,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.timestamp === nextProps.timestamp) {
      return true;
    } else {
      this.setState({
        editing: false,
        currentAbstract: null,
      });
      return false;
    }
  }

  render() {
    const { classes } = this.props;

    if (this.props.event.event === null) {
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Paper className={classes.paper}>
                <Button component={Link} to={`/events`} color="secondary">
                  Select Event
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </div>
      );
    }

    let bodyContent;

    let loadingGraphic = (
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <br />
        <br />
        <CircularProgress color="inherit" />
        <Typography variant="h4" color="textPrimary">
          Loading...
        </Typography>
      </Grid>
    );
    let noResultsFound = (
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <br />
        <br />
        <Typography className={classes.center} variant="h5">
          You have no submitted abstracts for this event
        </Typography>
      </Grid>
    );

    if (this.state.abstracts.length === 0) {
      if (this.state.loaded) {
        bodyContent = noResultsFound;
      } else {
        bodyContent = loadingGraphic;
      }
    } else {
      bodyContent = (
        <>
          {this.state.categories.map((category, index) => {
            return (
              <Paper className={classes.paper} key={`${category}${index}`}>
                <br />
                <Typography className={classes.center} variant="h5">
                  {category}
                </Typography>
                <br />
                <br />
                <DataTable
                  initialGridOptions={abstractGridOptions}
                  initialRowData={this.state.abstracts.filter(
                    (e) => e.topic === category
                  )}
                  actionButtons={AbstractGridActionButtons}
                  type="abstract"
                  setEditing={(e) => this.handleEditingChange(e)}
                  setCurrentPage={(e) => this.setCurrentAbstract(e)}
                  user={this.props.event.event}
                />
                <br />
              </Paper>
            );
          })}
        </>
      );
    }

    return (
      <div className={classes.root}>
        <Grid container spacing={2} style={{ marginBottom: "16px" }}>
          <Grid item xs={9}>
            <Paper className={classes.paper} style={{ marginBottom: "0px" }}>
              <Grid container>
                <Grid item xs={3}>
                  {this.state.editing ? (
                    <Button
                      onClick={() => this.handleEditingChange(false)}
                      variant="contained"
                      color="primary"
                    >
                      Back
                    </Button>
                  ) : null}
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    className={classes.center}
                    variant="h4"
                    color="textPrimary"
                  >
                    Abstract Voting
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={3} className={classes.downloadButtonContainer}>
            <CSVLink
              data={this.state.abstractsForExport}
              filename={`Voting-Abstracts-${moment().format(
                "MMMDD-HH;mm"
              )}.csv`}
              className={classes.downloadLink}
              target="_blank"
            >
              <Button
                variant="contained"
                size="large"
                className={classes.downloadButton}
              >
                <GetAppIcon style={{ marginRight: "8px" }} />
                Download Abstracts
              </Button>
            </CSVLink>
          </Grid>
          <Grid item xs={12} sm={12}>
            {this.state.editing ? (
              <AbstractInfo
                eventId={this.props.event.event.show_id}
                currentAbstract={this.state.currentAbstract}
                getAbstracts={this.getAbstracts.bind(this)}
                handleEditClose={this.handleEditClose.bind(this)}
                exhibitors={this.state.exhibitors}
                user={this.props.user.user}
              />
            ) : (
              <React.Fragment>{bodyContent}</React.Fragment>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    event: state.event,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeAuthenticatedUser: removeAuthenticatedUser,
      removeEvent: removeEvent,
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(AbstractsList));
