import React, { useState } from "react";
import axios from "axios";
import { Auth } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import { Img } from "react-image";
import { FileUploader } from "../Shared/FileUploader";
import { Formik, Form, Field, useField } from "formik";
import TextField from "@material-ui/core/TextField";
import Dialog from "../../Components/Dialog";
import moment from "moment";
import { EmailTemplate, FormatObject } from "../../Utilities/EmailTemplate";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: theme.spacing(9),
  },
  header: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    textAlign: "center",
  },
  divider: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  submitGridItem: {
    position: "fixed",
    bottom: "60px",
    // left: (props) => (props.sidebar?.open ? "120px" : "2px"),
    width: "100%",
    zIndex: 1,
    textAlign: "center",
    background: theme.palette.background.main,
    transition: theme.transitions.create(["left"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    borderStyle: "solid",
    borderColor: theme.palette.background.dark,
  },
  submitButton: {
    color: "white",
    padding: theme.spacing(2, 8),
    marginLeft: theme.spacing(4),
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      boxShadow: "none",
    },
  },
}));

const StaffInfoField = ({ ...props }) => {
  const [field, meta] = useField(props);

  if (field.value === null) {
    field.value = "";
  }
  if (field.name === "created_time") {
    field.value = moment(field.value).format("LLL");
  }
  if (typeof field.value === "boolean") {
    field.value = field.value.toString();
  }
  return (
    <>
      <Grid container>
        <Grid item xs>
          <Field
            className="text-input"
            component={TextField}
            margin="dense"
            variant="outlined"
            fullWidth
            {...field}
            {...props}
          />
          {meta.touched && meta.error ? (
            <div className="error" style={{ color: "red" }}>
              {meta.error}
            </div>
          ) : null}
        </Grid>
        {props.tooltip ? (
          <Grid
            item
            xs={1}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Tooltip title={props.tooltip} placement="right">
              <InfoIcon />
            </Tooltip>
          </Grid>
        ) : (
          <Grid xs={1} />
        )}
      </Grid>
    </>
  );
};

const StaffSwitchField = ({ ...props }) => {
  const [field, meta, helpers] = useField(props); //eslint-disable-line
  const { setValue } = helpers;

  if (field.value === null) {
    field.value = false;
  }
  let isDisabled = props.disabled;
  if (field.value && props.disabled) {
    isDisabled = false;
  }
  if (props.eventIsArchived) {
    isDisabled = true;
  }
  if (field.name === "isBoothAdmin") {
    isDisabled = props.disabled;
  }

  let openDialog = false;
  openDialog = props.openRequired ? true : false;

  const handleVisualToggle = () => {
    setValue(!field.value);
    if (openDialog && !field.value) {
      props.openDialog();
    }

    if (field.name.includes("physicalDays")) {
      if (!field.value) {
        props.setTempPhysicalPasses(props.tempPhysicalPasses - 1);
      } else {
        props.setTempPhysicalPasses(props.tempPhysicalPasses + 1);
      }
    }
    // if (field.name.includes("virtual")) {
    //   if (!field.value) {
    //     props.setTempVirtualPasses(props.tempVirtualPasses - 1);
    //   } else {
    //     props.setTempVirtualPasses(props.tempVirtualPasses + 1);
    //   }
    // }
  };
  // console.log(field.name, props.disabled, isDisabled);
  // console.log("field", field);
  // console.log("meta", meta);
  // console.log("helpers", helpers);
  // console.log(meta.touched, meta.error);
  return (
    <Grid container justifyContent="flex-start" alignItems="center">
      <Grid item xs={2} style={{ textAlign: "left" }}>
        <Field
          disabled={isDisabled}
          component={Switch}
          checked={field.value}
          onChange={handleVisualToggle}
          color="primary"
          name="Visual"
          inputProps={{ "aria-label": "Visual" }}
        />
      </Grid>
      <Grid item xs={9}>
        <Typography
          variant="body1"
          color={isDisabled ? "textSecondary" : "textPrimary"}
        >
          {props.label}
        </Typography>
      </Grid>
      {meta.touched && meta.error ? (
        <div className="error" style={{ color: "red" }}>
          {meta.error}
        </div>
      ) : null}
      {props.tooltip ? (
        <Grid
          item
          xs={1}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Tooltip title={props.tooltip} placement="right">
            <InfoIcon />
          </Tooltip>
        </Grid>
      ) : (
        <Grid item xs={1} />
      )}
    </Grid>
  );
};

const DialogSwitchOff = ({ ...props }) => {
  const [field, meta, helpers] = useField(props); //eslint-disable-line
  const { setValue } = helpers;

  let toggleOff = false;
  switch (props.name) {
    case "physical":
      toggleOff = props.values.physicalDays.some((item) => item);
      break;
    case "virtual":
      toggleOff = props.values.virtualDays.some((item) => item);
      break;
    default:
      break;
  }
  // console.log(toggleOff);
  const handleVisualToggle = () => {
    if (!toggleOff) {
      setValue(!field.value);
    }
  };

  return (
    <Button
      variant="contained"
      color="primary"
      fullWidth
      onClick={() => {
        props.selectDays();
        handleVisualToggle();
      }}
    >
      Done
    </Button>
  );
};

const wait = async function (ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export function StaffInfo(props) {
  const [success, setSuccess] = useState(false);
  const [selectDays, setSelectDays] = useState(false);
  const [isVirtual, setIsVirtual] = useState(false);
  // const [dayError, setDayError] = useState(false);
  const [file, setFile] = useState(null);
  const classes = useStyles();
  const [tempPhysicalPasses, setTempPhysicalPasses] = useState(
    props.availablePhysicalPasses
  );
  // tempVirtualPasses is used, but setting them is no longer used
  //eslint-disable-next-line
  const [tempVirtualPasses, setTempVirtualPasses] = useState(
    props.availableVirtualPasses
  );

  console.log(selectDays);

  const handleFileUpload = (file, meta, status) => {
    setFile(file);
  };

  let validateURL = (url) => {
    let error;
    const urlExpression =
      "^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$"; //eslint-disable-line
    let regex = new RegExp(urlExpression);
    if (url !== "") {
      if (url.match(regex)) {
        error = "";
      } else {
        error = "Invalid URL";
      }
    }
    return error;
  };

  let validateEmail = (value) => {
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  };

  let validateRequired = (value) => {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  };

  let CRUDtype = "put";
  if (!props.currentStaff) {
    CRUDtype = "post";
  }
  let noMorePhysicalPasses = !tempPhysicalPasses; //TODO HANDLE LOGIC HERE, MAY NEED BOOLEAN
  let noMoreVirtualPasses = !Boolean(tempVirtualPasses);

  console.log("noMorePhysicalPasses", noMorePhysicalPasses);
  console.log("setTempPhysicalPasses", tempPhysicalPasses);
  console.log("boothavailable", props.availablePhysicalPasses);

  const basePhysicalDays = [];
  const baseVirtualDays = [];

  const physicalDayOptions = [];
  // const virtualDayOptions = [];
  for (let i = 0; i < props.totalDays; i++) {
    let modifiedIndex = i + 1;
    basePhysicalDays.push(false);
    baseVirtualDays.push(false);
    physicalDayOptions.push(
      <StaffSwitchField
        key={`physical${modifiedIndex}`}
        name={`physicalDays[${i}]`}
        id={`physicalDays[${i}]`}
        label={`Day ${modifiedIndex}`}
        type="text"
        placeholder={`Day ${modifiedIndex}`}
        setTempPhysicalPasses={setTempPhysicalPasses}
        tempPhysicalPasses={tempPhysicalPasses}
        disabled={noMorePhysicalPasses}
      />
    );
    // virtualDayOptions.push(
    //   <StaffSwitchField
    //     key={`virtual${modifiedIndex}`}
    //     name={`virtualDays[${i}]`}
    //     id={`virtualDays[${i}]`}
    //     label={`Day ${modifiedIndex}`}
    //     type="text"
    //     placeholder={`Day ${modifiedIndex}`}
    //   />
    // );
  }

  let baseStaff = {
    first_name: "",
    last_name: "",
    email: "",
    title: "",
    department: "",
    linked_in_url: "",
    expertise_area: "",
    exhibition_id: "",
    booth_visible: false,
    booth_order: "",
    // account_id: '',
    account_name: "",
    // exhibition_id: '',
    // show_id: '',
    show_name: "",
    // attendee_id: '',
    // badge_key: '',
    // contact_id: '',
    site: "",
    roles: "Booth Staff",
    exhibitor_lead_retrieval: "",
    created_time: "",
    days: "",
    is_complimentary: "",
    note: "",
    photo_url: "",
    physical: false,
    virtual: true,
    linkOnly: false,
    physicalDays: basePhysicalDays,
    virtualDays: baseVirtualDays,
    isBoothAdmin: false,
  };

  if (props.currentStaff) {
    if (typeof props.currentStaff.days !== "string") {
      // this catches if staff is submitted with no changes and days is an array
      props.currentStaff.days = props.currentStaff.days.join(", ");
    }

    let daysAttending = props.currentStaff.days.split(", ");
    let sortedPhysicalDays = daysAttending.filter((day) => day.includes("Day"));
    let sortedVirtualDays = daysAttending.filter((day) =>
      day.includes("Virtual")
    );
    let currentPhysicalPasses = sortedPhysicalDays.length;
    let currentVirtualPasses = 0;
    if (props.currentStaff.booth_visible) {
      currentVirtualPasses = sortedVirtualDays.length;
    }

    const physicalDays = basePhysicalDays;
    const virtualDays = baseVirtualDays;
    // these sorts will break if total days of event is greater than 9
    sortedPhysicalDays.forEach((day) => {
      let dayAsNumber = day.charAt(day.length - 1);
      dayAsNumber = parseInt(dayAsNumber);
      dayAsNumber = dayAsNumber - 1;
      physicalDays[dayAsNumber] = true;
    });

    sortedVirtualDays.forEach((day, index) => {
      // let dayAsNumber = day.charAt(day.length - 1);
      // console.log(dayAsNumber);
      // dayAsNumber = parseInt(dayAsNumber);
      // dayAsNumber = dayAsNumber - 1;
      virtualDays[index] = true;
    });

    if (props.currentStaff.days.toLowerCase().includes("day")) {
      props.currentStaff.physical = true;
      props.currentStaff.physicalDays = physicalDays;
    } else {
      props.currentStaff.physical = false;
      props.currentStaff.physicalDays = basePhysicalDays;
    }
    if (props.currentStaff.days.toLowerCase().includes("virtual")) {
      props.currentStaff.virtual = true;
      props.currentStaff.virtualDays = virtualDays;
      props.currentStaff.linkOnly = false;
      if (!props.currentStaff.booth_visible) {
        props.currentStaff.linkOnly = true;
      }
    } else {
      props.currentStaff.virtual = false;
      props.currentStaff.virtualDays = baseVirtualDays;
      props.currentStaff.linkOnly = false;
    }
    props.currentStaff.currentStaffPhysicalPasses = currentPhysicalPasses;
    props.currentStaff.currentStaffVirtualPasses = currentVirtualPasses;
    if (props.currentStaff.physical) {
      props.currentStaff.currentStaffVirtualPasses = 0;
    }
  }

  // add booth admin check and make it a field for values to update
  let currentStaff;
  if (props.currentStaff) {
    currentStaff = {
      ...props.currentStaff,
      isBoothAdmin: props.currentStaff.roles
        .toLowerCase()
        .includes("booth admin"),
    };
  }

  return (
    <div className={classes.root}>
      <Formik
        initialValues={props.currentStaff ? currentStaff : baseStaff}
        onSubmit={async (values, { setSubmitting }) => {
          await new Promise((r) => setTimeout(r, 500));
          const newDays = [];
          let physicalPassesUsed = 0;
          let virtualPassesUsed = 0;
          if (values.physical || values.virtual || values.linkOnly) {
            if (values.physical) {
              values.physicalDays.forEach((day, index) => {
                if (day) {
                  newDays.push(`Day ${index + 1}`);
                  physicalPassesUsed += 1;
                }
              });
              // physical passes also include a virtual pass for free
              newDays.push(`Virtual`);
            } else if (values.virtual) {
              newDays.push(`Virtual`);
              if (values.booth_visible) {
                // booth visible counts towards virtual pass
                virtualPassesUsed += 1;
              }
            } else {
              // default staff is virtual
              newDays.push(`Virtual`);
            }
            values.days = newDays;

            if (values.isBoothAdmin) {
              // booth admin set to on
              if (!values.roles.toLowerCase().includes("booth admin")) {
                // current roles do NOT contain booth admin
                if (values.roles.toLowerCase().includes("booth staff")) {
                  // current roles do include booth staff,
                  values.roles = values.roles.replace(
                    "Booth Staff",
                    "Booth Admin"
                  );
                  // replace booth staff with booth admin
                } else {
                  values.roles = values.roles + ", Booth Admin";
                }
              }
            } else {
              // booth admin set to off
              if (values.roles.toLowerCase().includes("booth admin")) {
                // current roles do include booth admin
                if (!values.roles.toLowerCase().includes("booth staff")) {
                  // current roles do NOT have booth staff
                  values.roles = values.roles.replace(
                    "Booth Admin",
                    "Booth Staff"
                  );
                  // replace booth admin with booth staff
                }
              }
            }

            const user = await Auth.currentSession();

            let formatEmail = async (type, newRoles) => {
              let subject = `${props.event.show_name} - Staff ${type}: ${values.first_name} ${values.last_name}`;

              let userInformation = {
                name: `${values.first_name} ${values.last_name}`,
                email: values.email,
                jobTitle: values.title,
                company: props.event.account_name,
                department: values.department,
                linkedInUrl: values.linked_in_url,
                expertiseArea: values.expertise_area,
                boothOrder: values.booth_order,
                profilePicture: values.photo_url,
                attendance: values.days,
                roles: newRoles ? newRoles.join(", ") : values.roles,
              };

              let body1 = `Your booth staff has successfully been ${type} for ${props.event.show_name}. Below is the information attached we have received:`;
              let body2 = FormatObject(userInformation);
              // 'If you would now like to submit an abstract for consideration, please revisit ${this.props.event.data.landing_page_url} and click the Abstract Submission Link.';
              // let body3 = `You can access the virtual environment at ${this.props.event.data.landing_page_url}`;
              let body2a = `This edit was made by ${props.event.first_name} ${props.event.last_name}: ${props.event.email}`;
              let body2b =
                "To make additional changes, please revisit https://admin.planetconnect.com/.";
              let body3 =
                "If you have any questions, please contact Deb Hunter (dhunter@planetconnect.com).";
              let body4 = "Enjoy the event!";

              let bodyAdmin = `${body1}<br/>${body2}<br/>${body2a}<br/>${body2b}<br/>${body3}<br/>${body4}`;

              let emailObjectAdmin = {
                to: [
                  `${props.user.first_name} ${props.user.last_name} <${user.idToken.payload.email}>`,
                  // values.email,
                  "registration@planetconnect.com",
                ],
                subject: subject,
                body: bodyAdmin,
                title: userInformation.name,
                fromName: `${props.event.show_name} Registration`,
                banner: props.eventDetails.banner_location,
              };

              let bodyStaff = `${body1}<br/>${body2}<br/>${body3}<br/>${body4}`;

              let emailObjectStaff = {
                to: [
                  // user.idToken.payload.email,
                  `${values.first_name} ${values.last_name} <${values.email}>`,
                  // "registration@planetconnect.com"
                ],
                subject: subject,
                body: bodyStaff,
                title: userInformation.name,
                fromName: `${props.event.show_name} Registration`,
                banner: props.eventDetails.banner_location,
              };

              let bodyOfEmailAdmin = EmailTemplate(emailObjectAdmin);
              emailObjectAdmin.body = bodyOfEmailAdmin;
              await axios({
                method: "POST",
                url: `/mail`,
                data: emailObjectAdmin,
              });
              let bodyOfEmailStaff = EmailTemplate(emailObjectStaff);
              emailObjectStaff.body = bodyOfEmailStaff;
              await axios({
                method: "POST",
                url: `/mail`,
                data: emailObjectStaff,
              });
            };

            if (file) {
              let formData = new FormData();
              formData.append("file", file);

              try {
                let filenamePath = `${props.show.pharma_company.toLowerCase()}/${
                  props.show.show_code
                }/${file.name}`;
                let dataShape = {
                  operation: "putObject",
                  file_name: filenamePath,
                  bucket_name: "eventhorizon-assets-public",
                };

                const signedUrl = await axios({
                  method: "POST",
                  url: "/upload/signed",
                  headers: {
                    idtoken: user.idToken.jwtToken,
                  },
                  data: dataShape,
                });

                await fetch(signedUrl.data, {
                  method: "PUT",
                  body: file,
                });

                values.photo_url = signedUrl.data.split("?")[0];
              } catch (error) {
                console.log(error);
              }
            }
            // update exhibit booth passes here
            let finalPhysicalUsedStaff;
            let finalVirtualUsedStaff;
            // PHYSICAL
            if (props.currentStaff) {
              if (
                props.currentStaff.currentStaffPhysicalPasses ===
                physicalPassesUsed
              ) {
                // passes did not change, pass through props.usedStaffPhysical
                finalPhysicalUsedStaff = props.usedStaffPhysical;
              } else {
                // determine new number of used passes to pass
                finalPhysicalUsedStaff =
                  physicalPassesUsed -
                  props.currentStaff.currentStaffPhysicalPasses +
                  props.usedStaffPhysical;
              }
            } else {
              finalPhysicalUsedStaff =
                physicalPassesUsed + props.usedStaffPhysical;
            }
            // VIRTUAL
            if (props.currentStaff) {
              if (
                props.currentStaff.currentStaffVirtualPasses ===
                virtualPassesUsed
              ) {
                // passes did not change, pass through props.usedStaffVirtual
                finalVirtualUsedStaff = props.usedStaffVirtual;
              } else {
                // determine new number of used passes to pass
                finalVirtualUsedStaff =
                  virtualPassesUsed -
                  props.currentStaff.currentStaffVirtualPasses +
                  props.usedStaffVirtual;
              }
            } else {
              finalVirtualUsedStaff =
                virtualPassesUsed + props.usedStaffVirtual;
            }

            let updatedExhibitPasses = {
              id: props.exhibitionId,
              available_physical_staff: props.originalAvailablePhysicalPasses,
              available_virtual_staff: props.originalAvailableVirtualPasses,
              used_physical_staff: finalPhysicalUsedStaff,
              used_virtual_staff: finalVirtualUsedStaff,
            };

            function staffAdminCheck(valueRoles, existingRoles) {
              let modifyRoles;
              if (valueRoles) {
                modifyRoles = valueRoles?.split(", ");
                if (!modifyRoles.includes("Booth Staff")) {
                  if (!modifyRoles.includes("Booth Admin")) {
                    modifyRoles.push("Booth Staff");
                  }
                }
              }
              if (existingRoles) {
                let oldRoles = existingRoles.split(", ");
                modifyRoles = [...modifyRoles, ...oldRoles];
              }
              return modifyRoles;
            }

            if (CRUDtype === "put") {
              let newRoles = staffAdminCheck(values?.roles);
              const response = await axios({
                method: "put",
                url: `/zoho/show-attendees/${props.currentStaff.attendee_id}`,
                headers: { idtoken: user.idToken.jwtToken },
                data: {
                  values,
                  showId: props.showId,
                  accountId: props.accountId,
                  exhibitionId: props.exhibitionId,
                  attendeeId: props.currentStaff.attendee_id,
                  contactId: props.currentStaff.contact_id,
                  showRoles: newRoles,
                },
              });

              await axios({
                method: "put",
                url: `/zoho/exhibitions/${props.showId}/${props.accountId}/${props.exhibitionId}/passes`,
                headers: { idtoken: user.idToken.jwtToken },
                data: updatedExhibitPasses,
              });
              // end update

              if (response.data) {
                await formatEmail("updated", newRoles);
                await setSuccess(true);
              } else {
                alert(
                  "Your attendee could not be updated. Please contact support."
                );
              }
            } else if (CRUDtype === "post") {
              const existingAttendee = await axios({
                method: "get",
                url: `/show-attendees/${props.showId}/${values.email}`,
                headers: { idtoken: user.idToken.jwtToken },
              });

              if (existingAttendee.data.length === 0) {
                const response = await axios({
                  method: "post",
                  url: `/zoho/show-attendees/staff/${props.showId}/${props.exhibitionId}`,
                  headers: { idtoken: user.idToken.jwtToken },
                  data: {
                    values,
                    showId: props.showId,
                    accountId: props.accountId,
                    exhibitionId: props.exhibitionId,
                  },
                });
                await axios({
                  method: "put",
                  url: `/zoho/exhibitions/${props.showId}/${props.accountId}/${props.exhibitionId}/passes`,
                  headers: { idtoken: user.idToken.jwtToken },
                  data: updatedExhibitPasses,
                });

                if (response.data) {
                  await formatEmail("added");
                  await setSuccess(true);
                } else {
                  alert(
                    "Your attendee could not be created. Please contact support."
                  );
                }
              } else if (!existingAttendee.data[0].exhibition_id) {
                let newRoles = staffAdminCheck(
                  values?.roles,
                  existingAttendee.data[0].roles
                );
                const response = await axios({
                  method: "put",
                  url: `/zoho/show-attendees/${existingAttendee.data[0].attendee_id}`,
                  headers: { idtoken: user.idToken.jwtToken },
                  data: {
                    values,
                    showId: props.showId,
                    accountId: props.accountId,
                    exhibitionId: props.exhibitionId,
                    attendeeId: existingAttendee.data[0].attendee_id,
                    contactId: existingAttendee.data[0].contact_id,
                    showRoles: newRoles,
                  },
                });

                await axios({
                  method: "put",
                  url: `/zoho/exhibitions/${props.showId}/${props.accountId}/${props.exhibitionId}/passes`,
                  headers: { idtoken: user.idToken.jwtToken },
                  data: updatedExhibitPasses,
                });
                // end update

                if (response.data) {
                  await formatEmail("added", newRoles);
                  await setSuccess(true);
                } else {
                  alert(
                    "Your attendee could not be created. Please contact support."
                  );
                }
              } else {
                alert("This attendee is already a booth staff");
              }
            }

            await wait(3000);
          } else {
            // setDayError(true);
            // console.log("DAY ERROR WOULD TRIGGER");
          }

          await setSubmitting(false);
        }}
      >
        {({ isSubmitting, values, handleChange }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Paper className={classes.paper}>
                  <Typography className={classes.header} variant="h5">
                    Staff Information
                  </Typography>
                  <Divider className={classes.divider} />
                  <StaffInfoField
                    disabled={props.currentStaff?.attendee_id ? true : false}
                    name="email"
                    id="email"
                    label="Email"
                    type="text"
                    placeholder="Email"
                    tooltip="The email associated with this current staff."
                    validate={validateEmail}
                  />
                  {props.currentStaff && (
                    <Typography color="textSecondary" variant="subtitle2">
                      Ensure staff details below belong to the email above.{" "}
                      <br />
                      {!props.currentStaff?.roles
                        ?.toLowerCase()
                        ?.includes("booth admin")
                        ? "If they do not, please unassign this staff and add a new."
                        : "If this is not the correct Booth Admin, you may elevate your Staff to Booth Admin. If you would like to remove yourself as Booth Admin, please reach out to support to make that change."}
                    </Typography>
                  )}
                  <StaffInfoField
                    name="first_name"
                    id="first_name"
                    label="First Name"
                    type="text"
                    placeholder="First Name"
                    tooltip="Forename."
                    validate={validateRequired}
                    disabled={props.eventIsArchived}
                  />
                  <StaffInfoField
                    name="last_name"
                    id="last_name"
                    label="Last Name"
                    type="text"
                    placeholder="Last Name"
                    tooltip="Surname."
                    validate={validateRequired}
                    disabled={props.eventIsArchived}
                  />
                  <StaffInfoField
                    name="title"
                    id="title"
                    label="Title"
                    type="text"
                    placeholder="Title"
                    tooltip="Job title or profession."
                    validate={validateRequired}
                    disabled={props.eventIsArchived}
                  />
                  <StaffInfoField
                    name="department"
                    id="department"
                    label="Department"
                    type="text"
                    placeholder="Department"
                    tooltip="Department within the company."
                    disabled={props.eventIsArchived}
                  />
                  <StaffInfoField
                    name="linked_in_url"
                    id="linked_in_url"
                    label="LinkedIn Url"
                    type="text"
                    placeholder="LinkedIn Url"
                    tooltip="The URL to access your LinkedIn Profile."
                    disabled={props.eventIsArchived}
                    validate={validateURL}
                  />
                  <StaffInfoField
                    name="expertise_area"
                    id="expertise_area"
                    label="Area of Expertise"
                    type="text"
                    placeholder="Area of Expertise"
                    tooltip="Specific field of study worked in."
                    disabled={props.eventIsArchived}
                  />
                  <StaffInfoField
                    name="booth_order"
                    id="booth_order"
                    label="Order in Booth"
                    type="number"
                    InputProps={{ inputProps: { min: 0, max: 100 } }}
                    placeholder="10"
                    tooltip="Staff are displayed in the booth based on booth
                    order from low to high. If two staff have the same booth number, 
                    alphabetical sorting is used to determine order."
                    disabled={props.eventIsArchived}
                  />
                  <Divider className={classes.divider} />
                  {/* {!props.currentStaff?.roles
                    ?.toLowerCase()
                    ?.includes("booth admin") && ( */}
                  <>
                    <Grid container>
                      <Grid item xs={6}>
                        <StaffSwitchField
                          name="booth_visible"
                          id="booth_visible"
                          label="Visible in Virtual Booth"
                          type="text"
                          placeholder="Visible in Virtual Booth"
                          tooltip="Toggle on will display this staff in the Virtual Booth. Toggle off will hide them. 
                    Having a linked staff that is not booth visible will link their activities to the Virtual Booth without showing the person as Staff."
                          disabled={noMoreVirtualPasses && !props.currentStaff}
                          eventIsArchived={props.eventIsArchived}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        {props.availableVirtualPasses !== 1 &&
                        noMoreVirtualPasses &&
                        !props.currentStaff ? (
                          <Typography color="error" variant="body2">
                            You are out of virtual passses. Return to Staff List
                            page to purchase more.
                          </Typography>
                        ) : null}
                      </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                    <Grid container>
                      <Grid item xs={6}>
                        <StaffSwitchField
                          name="physical"
                          id="physical"
                          label="On-site Booth Staff"
                          type="text"
                          placeholder="On-site Booth Staff"
                          tooltip="Staff will be attending the event in person and will be present at the booth. 
                        Toggle on and then select which days they will attend. Each day is one pass."
                          openRequired={true}
                          openDialog={() => {
                            setSelectDays(true);
                            setIsVirtual(false);
                          }}
                          eventIsArchived={props.eventIsArchived}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: "16px",
                        }}
                      >
                        {!props.eventIsArchived && (
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={values.physical ? false : true}
                            style={{ padding: "8px 40px" }}
                            onClick={() => {
                              setSelectDays(true);
                              setIsVirtual(false);
                            }}
                          >
                            Select Days
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                    <Typography variant="body1">
                      This Staff uses: <br />
                      {values.physical
                        ? values.physicalDays.filter((x) => x === true).length
                        : 0}{" "}
                      On-site passes ---{" "}
                      {!values.physical ? (values.booth_visible ? 1 : 0) : 0}{" "}
                      Virtual passes
                    </Typography>
                    <Divider className={classes.divider} />
                    <Grid container>
                      <Grid item xs={6}>
                        <StaffSwitchField
                          name="isBoothAdmin"
                          id="isBoothAdmin"
                          label="Is Booth Admin"
                          type="text"
                          placeholder="Is Booth Admin"
                          tooltip="Toggle on will assign this staff as a Booth Admin and give editing privileges. 
                          NOTE: If you wish to unassign yourself as Booth Admin, please reach out to support to make the change."
                          disabled={
                            props.user.email === props?.currentStaff?.email
                          }
                          eventIsArchived={props.eventIsArchived}
                        />
                      </Grid>
                      <Grid item xs={6} />
                    </Grid>
                    {/* <Grid container>
                        {values.physical ? (
                          <Typography color="primary" variant="body2">
                            On-site pass includes access to EventHorizon at no
                            additonal cost. No need to assign a virtual pass.
                          </Typography>
                        ) : (
                          <>
                            <Grid item xs={6}>
                              <StaffSwitchField
                                name="virtual"
                                id="virtual"
                                label="Virtual Booth Staff"
                                type="text"
                                placeholder="Virtual Booth Staff"
                                tooltip="Staff will be attending the event virtually and will attend the booth via the virtual environment. 
                        Toggle on to assign the virtual pass. One virtual pass covers all of the event."
                                disabled={noMoreVirtualPasses}
                                setTempVirtualPasses={setTempVirtualPasses}
                                tempVirtualPasses={tempVirtualPasses}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              style={{
                                textAlign: "left",
                                alignSelf: "center",
                                paddingLeft: "16px",
                              }}
                            >
                              <Typography color="primary" variant="body2">
                                Virtual pass covers all of virtual event. <br />{" "}
                                No day selection required.
                              </Typography>
                            </Grid>
                          </>
                        )}
                      </Grid> */}
                    {/* {dayError ? (
                        <Typography color="error" variant="body2">
                          Selecting On-Site or Virtual is Required
                        </Typography>
                      ) : null} */}
                    {/* <Divider className={classes.divider} /> */}
                  </>
                  {/* )} */}
                  <Typography className={classes.header} variant="h6">
                    Additional Read-Only Information
                  </Typography>
                  {props.currentStaff ? (
                    <StaffInfoField
                      disabled
                      name="created_time"
                      id="created_time"
                      label="User created at time"
                      type="text"
                      placeholder="User created at time"
                      tooltip="Date and Time this user was created."
                    />
                  ) : (
                    <React.Fragment />
                  )}
                  <StaffInfoField
                    disabled
                    name="roles"
                    id="roles"
                    label="Roles"
                    type="text"
                    placeholder="Roles"
                    tooltip="What roles are assigned to this user."
                  />
                  {/* <StaffInfoField
                                    disabled
                                        name='days'
                                        id='days'
                                        label='Days attending show'
                                        type='text'
                                        placeholder='Days attending show'
                                    /> */}
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper className={classes.paper}>
                  <Grid container>
                    <Grid item xs={1} />
                    <Grid item xs={10}>
                      <Typography className={classes.header} variant="h5">
                        Current Image
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={1}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Tooltip
                        title="Upload your profile picture."
                        placement="right"
                      >
                        <InfoIcon />
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Divider className={classes.divider} />
                  {props.currentStaff && (
                    <Img src={props.currentStaff.photo_url} width="100%" />
                  )}
                  <Divider className={classes.divider} />
                  {!props.eventIsArchived ? (
                    <>
                      <Typography
                        className={classes.header}
                        variant="subtitle1"
                      >
                        Update Photo:
                      </Typography>
                      <Typography
                        className={classes.header}
                        variant="subtitle2"
                      >
                        Accepts PNG, JPG and GIF. Square aspect ratio such as
                        800px by 800px is recommended. Max size 50mb.
                      </Typography>
                      <FileUploader
                        handleFileUpload={handleFileUpload.bind(this)}
                        acceptedTypes={[".png", ".jpg", ".jpeg", ".gif"]}
                      />
                    </>
                  ) : (
                    <Typography
                      className={classes.header}
                      variant="subtitle2"
                      style={{ visibility: "hidden" }}
                    >
                      A whole bunch of this text is necessary to keep this box
                      happy and the correct size. I'm not sure why. So I hid it.
                    </Typography>
                  )}
                </Paper>
              </Grid>
            </Grid>
            {!props.eventIsArchived && (
              <Grid
                item
                xs={12}
                className={classes.submitGridItem}
                style={{ left: props.sidebar?.open ? "120px" : "2px" }}
              >
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  className={classes.submitButton}
                  color="primary"
                  variant="contained"
                  size="large"
                >
                  <Typography variant="h6">
                    {isSubmitting ? "Saving..." : "Save Changes"}
                  </Typography>
                </Button>
              </Grid>
            )}
            <Dialog open={selectDays} handleClose={() => setSelectDays(false)}>
              {isVirtual ? (
                <Typography align="center" variant="h6">
                  Virtual
                </Typography>
              ) : (
                <>
                  <Typography align="center" variant="h6">
                    On-Site
                  </Typography>
                  <Typography align="center" variant="body1">
                    <b>{props.availablePhysicalPasses}</b> passes available for
                    assigning.
                    <br />
                    Each day is one pass.
                  </Typography>
                  <br />
                  {noMorePhysicalPasses &&
                    props.availablePhysicalPasses < 1 && (
                      <Typography color="error" style={{ textAlign: "center" }}>
                        You are out of passes.
                        <br />
                        Return to the Staff List page
                        <br />
                        to purchase more.
                      </Typography>
                    )}
                  <br />
                </>
              )}
              {isVirtual ? null : physicalDayOptions}
              <DialogSwitchOff
                name={isVirtual ? "virtual" : "physical"}
                id={isVirtual ? "virtual" : "physical"}
                label={isVirtual ? "virtual" : "physical"}
                placeholder={isVirtual ? "virtual" : "physical"}
                selectDays={() => setSelectDays(false)}
                values={values}
              />
            </Dialog>
          </Form>
        )}
      </Formik>
      <Dialog open={success} handleClose={() => props.handleEditClose()}>
        <Typography variant="h5">Success!</Typography>
        <Typography>Your Staff has been updated.</Typography>
        <br />
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={() => props.handleEditClose()}
        >
          X Close
        </Button>
      </Dialog>
    </div>
  );
}
