import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
// import { withAuthenticator } from "aws-amplify-react";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

//Other
import { Auth } from "aws-amplify";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import removeAuthenticatedUser from "../Store/Actions/removeUser";
import setSidebar from "../Store/Actions/setSidebar";
import removeEvent from "../Store/Actions/removeEvent";
// import banner from "../Assets/Banner.jpg";
import PCIlogo from "../Assets/PCI-alpha-whiteText.png";

// Icons
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import BusinessIcon from "@material-ui/icons/Business";
import HelpIcon from "@material-ui/icons/Help";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HomeIcon from "@material-ui/icons/Home";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import PanoramaIcon from "@material-ui/icons/Panorama";
import ForumIcon from "@material-ui/icons/Forum";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import WorkIcon from "@material-ui/icons/Work";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import SettingsIcon from "@material-ui/icons/Settings";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import MyleadsIcon from "@material-ui/icons/HowToReg";
import LocalCafeIcon from "@material-ui/icons/LocalCafe";
import BarChartIcon from "@material-ui/icons/BarChart";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import CategoryIcon from "@material-ui/icons/Category";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import DevicesIcon from "@material-ui/icons/Devices";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import EventIcon from "@material-ui/icons/Event";
import PeopleIcon from "@material-ui/icons/People";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import AssignmentIcon from "@material-ui/icons/Assignment";
import HowToVoteIcon from "@material-ui/icons/HowToVote";
import BallotIcon from "@material-ui/icons/Ballot";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import PollIcon from "@material-ui/icons/Poll";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import ViewQuiltIcon from "@material-ui/icons/ViewQuilt";

import { routerArray } from "../config";
import arrayComparison from "../Utilities/arrayComparison";

const drawerWidth = 240;
const rightDrawerWidth = 340;

const useStyles = (theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#ebebeb",
    minHeight: "100vh",
    position: "relative",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.primary.main,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarShiftRight: {
    marginRight: rightDrawerWidth,
    width: `calc(100% - ${rightDrawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarShiftBoth: {
    marginRight: rightDrawerWidth,
    width: `calc(100% - ${drawerWidth + rightDrawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  rightMenuButton: {
    marginLeft: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  rightDrawer: {
    width: rightDrawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  rightDrawerOpen: {
    width: rightDrawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    // [theme.breakpoints.up("sm")]: {
    //   width: theme.spacing(9) + 1,
    // },
  },
  rightDrawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(0) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(0) + 1,
    },
    // width: '0px',
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: theme.spacing(5),
  },
  title: {
    flexGrow: 1,
  },
  adminTitle: {
    marginLeft: theme.spacing(2),
    color: "#FFF",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  showName: {
    padding: theme.spacing(1, 2),
    background: "#FFFFFF",
    borderRadius: theme.spacing(0.5),
  },
  showNameFooter: {
    background: theme.palette.primary.light,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(0.5),
    textAlign: "center",
    lineHeight: "1.2",
    fontSize: "1.1rem",
  },
  banner: {
    height: "60px",
    marginTop: "4px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  footerContainer: {
    position: "fixed",
    bottom: "0px",
    right: "-1px",
    width: `calc(101% - 56px)`,
    height: "61px",
    zIndex: 1,
    borderStyle: "solid",
    borderColor: theme.palette.background.dark,
    textAlign: "right",
    background: "#FFF",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  footerContainerSidebarOpen: {
    position: "fixed",
    bottom: "0px",
    right: "-1px",
    width: `calc(101% - ${drawerWidth}px)`,
    height: "61px",
    zIndex: 1,
    borderStyle: "solid",
    borderColor: theme.palette.background.dark,
    textAlign: "right",
    background: "#FFF",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
});

class MiniDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      allowedRoutes: [],
    };
  }

  components = {
    IconButton,
    MenuIcon,
    OndemandVideoIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
    BusinessIcon,
    HelpIcon,
    ExitToAppIcon,
    HomeIcon,
    FilterNoneIcon,
    PanoramaIcon,
    ForumIcon,
    AccessTimeIcon,
    WorkIcon,
    BubbleChartIcon,
    SettingsIcon,
    GpsFixedIcon,
    MyleadsIcon,
    LocalCafeIcon,
    BarChartIcon,
    AssignmentTurnedInIcon,
    BusinessCenterIcon,
    EventIcon,
    PeopleIcon,
    PictureAsPdfIcon,
    EqualizerIcon,
    CategoryIcon,
    AccountCircleIcon,
    DevicesIcon,
    FormatListBulletedIcon,
    AssignmentIcon,
    HowToVoteIcon,
    BallotIcon,
    GroupAddIcon,
    PollIcon,
    AddShoppingCartIcon,
    ViewQuiltIcon,
  };

  signOut = async () => {
    await this.props.removeEvent();

    await this.props.removeAuthenticatedUser();

    await Auth.signOut({ global: true });
  };

  componentWillUnmount() {
    this.props.history.push("/");
  }

  render() {
    const { classes } = this.props;

    let eventIsArchived = false;
    if (this.props.archived.archived) {
      if (!this.props.user.user.email.includes("jvindua@planetconnect.com")) {
        eventIsArchived = true;
      }
    }

    let allowedRoutes = routerArray.filter((route) => {
      const includesRole = arrayComparison(
        route.allowedRoles,
        this.props.user.user.roles.split(", ")
      );

      if (includesRole) {
        return route;
      } else {
        return false;
      }
    });

    const usedRoleArray = ["Admin"];
    let navigationIcons = allowedRoutes.map((navigation, index) => {
      const TagName = this.components[navigation.icon];
      let divider;
      let roleName;
      let finalRole;
      let additionalDivider;

      if (navigation.allowedRoles[0] === "Event Admin" && eventIsArchived) {
        if (navigation.title !== "Event Info") {
          return null;
        }
      }
      if (navigation.allowedRoles[0] === "Booth Admin" && eventIsArchived) {
        if (navigation.title === "New Orders") {
          return null;
        }
      }

      if (!usedRoleArray.includes(navigation.allowedRoles[0])) {
        usedRoleArray.push(navigation.allowedRoles[0]);
        finalRole = navigation.allowedRoles[0];
        if (navigation.allowedRoles.length > 1) {
          if (navigation.allowedRoles[0] === "Poster Presenter") {
            finalRole = "Presenter / Speaker";
          }
        }
        divider = <Divider />;
        roleName = (
          <Typography
            variant="h6"
            color="primary"
            style={{ background: "#efefef", paddingLeft: "16px" }}
          >
            {this.props.sidebar.open ? finalRole : "-----"}
          </Typography>
        );
      }

      if (navigation.title === "Leads") {
        divider = <Divider />;
        additionalDivider = (
          <Typography
            variant="h6"
            color="primary"
            style={{ background: "#efefef", paddingLeft: "16px" }}
          >
            {this.props.sidebar.open ? "Reports" : "-----"}
          </Typography>
        );
      }
      // console.log(navigation.allowedRoles[0]);

      return (
        <React.Fragment key={navigation.title}>
          {divider}
          {roleName}
          {additionalDivider}
          <Tooltip title={navigation.title} placement="right">
            <ListItem button component={Link} to={navigation.route}>
              <ListItemIcon>
                <TagName />
              </ListItemIcon>
              <ListItemText primary={navigation.title} />
            </ListItem>
          </Tooltip>
          {/* {divider} */}
        </React.Fragment>
      );
    });

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: this.props.sidebar.open,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => this.props.setSidebar(true)}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: this.props.sidebar.open,
              })}
            >
              <MenuIcon />
            </IconButton>
            {!this.props.sidebar.open && <div style={{ marginLeft: "28px" }} />}
            <Grid container alignItems="center">
              <Grid item>
                <img height="56px" src={PCIlogo} alt="PCI-logo" />
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.adminTitle}>
                  Admin Panel
                </Typography>
              </Grid>
            </Grid>
            {/* <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography
                  variant="h6"
                  color="textPrimary"
                  className={classes.showName}
                >
                  {this.props.event.event === null
                    ? "No Event Selected"
                    : this.props.event.event.show_name}
                </Typography>
              </Grid>
              <Grid item>
                {this.props.user.user && (
                  <img className={classes.banner} src={banner} alt="Banner" />
                )}
              </Grid>
            </Grid> */}
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          anchor="left"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: this.props.sidebar.open,
            [classes.drawerClose]: !this.props.sidebar.open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: this.props.sidebar.open,
              [classes.drawerClose]: !this.props.sidebar.open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={() => this.props.setSidebar(false)}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            {navigationIcons}
            <Divider />
            <Tooltip
              title="Sign Out"
              placement="right"
              style={{ marginBottom: "40px" }}
            >
              <ListItem
                onClick={this.signOut}
                button
                key={"Sign Out"}
                component={Link}
                to={"/"}
              >
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary={"Sign Out"} />
              </ListItem>
            </Tooltip>
          </List>
        </Drawer>
        <main className={classes.content}>
          {this.props.children}
          <footer>
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              className={
                this.props.sidebar.open
                  ? classes.footerContainerSidebarOpen
                  : classes.footerContainer
              }
            >
              <Grid item xs={6} sm={6}>
                <Typography
                  variant="h6"
                  color="textPrimary"
                  className={classes.showNameFooter}
                >
                  {this.props.event.event === null
                    ? "No Event Selected"
                    : this.props.event.event.show_name}
                </Typography>
              </Grid>
              <Grid item xs={1} sm={1} md={3} style={{ marginRight: "8px" }}>
                <Tooltip
                  title={`Have questions or need help? Use the support bubble in the lower left or click this icon to email us directly.`}
                  placement="top"
                >
                  <a
                    href="mailto:exhibitorsupport@planetconnect.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <HelpIcon />
                  </a>
                </Tooltip>
              </Grid>
            </Grid>
          </footer>
        </main>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    sidebar: state.sidebar,
    user: state.user,
    event: state.event,
    archived: state.archived,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeAuthenticatedUser: removeAuthenticatedUser,
      setSidebar: setSidebar,
      removeEvent: removeEvent,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(useStyles)(MiniDrawer)));
