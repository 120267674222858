import React, { Component } from "react";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { ProductInfo } from "./ProductInfo";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import { ProductGridActionButtons } from "./ProductGridActionButtons";
import { productGridOptions } from "./productGridOptions";
import { DataTable } from "../Shared/DataTable";
import Dialog from "../../Components/Dialog";
import { EmailTemplate, FormatObject } from "../../Utilities/EmailTemplate";

// Configure Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import removeAuthenticatedUser from "../../Store/Actions/removeUser";
import removeEvent from "../../Store/Actions/removeEvent";

import ForceLogOut from "../Shared/ForceLogOut";

const useStyles = (theme) => ({
  // root: {
  //     display: 'flex',
  //     flexWrap: 'wrap',
  // },
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  center: {
    textAlign: "center",
    // marginBottom: theme.spacing(1),
  },
});

class ProductsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      eventDetails: null,
      show: null,
      maxProduct: 0,
      editing: false,
      deleting: false,
      currentProduct: null,
      loaded: false,
    };
  }

  async componentDidMount() {
    if (this.props.event.event !== null) {
      await this.getProducts();
      await this.getEventDetails();
      await this.getShow();
    }
  }

  getProducts() {
    try {
      Auth.currentSession().then((data) => {
        axios({
          method: "get",
          url:
            `/products/` +
            this.props.event.event.show_id +
            "/" +
            this.props.event.event.exhibition_id,
          headers: { idtoken: data.idToken.jwtToken },
          params: {
            order_by: "booth_order",
            order_dir: "asc",
          },
        })
          .then((response) => {
            if (response.data.data) {
              this.setState({
                products: response.data.data,
                loaded: true,
              });
            } else {
              this.setState({ products: [], loaded: true });
            }
          })
          .catch((error) => {
            console.log(error);
          });

        axios({
          method: "get",
          url:
            `/exhibitions/` +
            this.props.event.event.show_id +
            "/accountid/" +
            this.props.event.event.exhibition_id, //account id is arbitrary but necessary to hit proper route
          // url: `/exhibitions/` + this.props.event.event.show_id + "/" + this.props.user.user.account_id + "/" + this.props.event.event.exhibition_id,
          headers: { idtoken: data.idToken.jwtToken },
        })
          .then((response) => {
            this.setState({
              maxProduct: response.data[0].max_product,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      });
    } catch (error) {
      if (error === "No current user") {
        console.log(error, "log them out");
        try {
          ForceLogOut(
            this.props.removeEvent,
            this.props.removeAuthenticatedUser
          );
        } catch (error) {
          console.log("ForceLogOut", error);
        }
      }
      console.log(error);
    }
  }

  async getEventDetails() {
    const user = await Auth.currentSession();

    let eventDetails = await axios({
      method: "get",
      url: "events/details/" + this.props.event.event.show_id,
      headers: { idtoken: user.idToken.jwtToken },
    });
    this.setState({ eventDetails: eventDetails.data });
  }

  async getShow() {
    const user = await Auth.currentSession();

    let myShow = await axios({
      method: "get",
      url: `/shows/${this.props.event.event.show_id}`,
      headers: { idtoken: user.idToken.jwtToken },
    });
    myShow = myShow.data[0];
    await this.setState({ show: myShow });
  }

  handleEditingChange(e) {
    this.setState({
      editing: e,
    });
  }

  handleCreatingChange(e) {
    this.setState({
      editing: e,
      currentProduct: null,
    });
  }

  async handleDeletingChange(e) {
    this.setState({
      deleting: e,
    });
  }

  handleDeletingCancel() {
    this.setState({
      deleting: false,
    });
  }

  handleEditClose() {
    this.setState({
      editing: false,
      currentProduct: null,
    });

    this.getProducts();
  }

  async handleDeletingConfirm(e) {
    await this.setState({ deleteInProcess: true });
    const user = await Auth.currentSession();

    await axios({
      method: "delete",
      url: `/products/` + this.state.currentProduct.product_id,
      headers: { idtoken: user.idToken.jwtToken },
    });

    let newProductsArray = await this.state.products.filter((e) => {
      if (this.state.currentProduct.product_id !== e.product_id) {
        return true;
      } else {
        return false;
      }
    });

    let formatEmail = async () => {
      let subject = `${this.props.event.event.show_name} - Product deleted.`;

      let productInformation = {
        productName: this.state.currentProduct.name,
        description: this.state.currentProduct.description,
        productUrl: this.state.currentProduct.product_url,
        videoUrl: this.state.currentProduct.product_video_url,
        imageUrl: this.state.currentProduct.product_image_url,
        isPublic: this.state.currentProduct.is_public,
        productOrder: this.state.currentProduct.booth_order,
      };

      let body1 = `Your product has successfully been deleted for ${this.props.event.event.show_name}. Below is the information attached we have received:`;
      let body2 = FormatObject(productInformation);
      // 'If you would now like to submit an abstract for consideration, please revisit ${this.props.event.data.landing_page_url} and click the Abstract Submission Link.';
      // let body3 = `You can access the virtual environment at ${this.props.event.data.landing_page_url}`;
      let body3 = "Enjoy the event!";

      let body = `${body1}<br/>${body2}<br/>${body3}`;

      let emailObject = {
        to: [
          `${this.props.user.user.first_name} ${this.props.user.user.last_name}<${user.idToken.payload.email}>`,
          "registration@planetconnect.com",
        ],
        subject: subject,
        body: body,
        title: productInformation.productName,
        fromName: `${this.props.event.event.show_name} Registration`,
        banner: this.state.eventDetails.banner_location,
      };

      let bodyOfEmail = EmailTemplate(emailObject);
      emailObject.body = bodyOfEmail;
      await axios({
        method: "POST",
        url: `/mail`,
        data: emailObject,
      });
    };

    await formatEmail();

    await this.setState({
      products: newProductsArray,
      currentProduct: null,
      deleteInProcess: false,
      deleting: false,
    });
    await this.getProducts();
  }

  setCurrentProduct(e) {
    this.setState({
      currentProduct: e,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.timestamp === nextProps.timestamp) {
      return true;
    } else {
      this.setState({
        editing: false,
        currentProduct: null,
      });
      return false;
    }
  }

  render() {
    const { classes } = this.props;

    let eventIsArchived = false;
    if (this.props.archived.archived) {
      if (!this.props.user.user.email.includes("jvindua@planetconnect.com")) {
        eventIsArchived = true;
      }
    }

    if (this.props.event.event === null) {
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Paper className={classes.paper}>
                <Button component={Link} to={`/events`} color="secondary">
                  Select Event
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </div>
      );
    }
    let bodyContent;

    let loadingGraphic = (
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <br />
        <br />
        <CircularProgress color="inherit" />
        <Typography variant="h4" color="textPrimary">
          Loading...
        </Typography>
      </Grid>
    );
    let noResultsFound = (
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <br />
        <br />
        <Typography className={classes.center} variant="h5">
          You have no submitted products for this event
        </Typography>
      </Grid>
    );

    if (!this.state.products.length) {
      if (this.state.editing) {
        bodyContent = (
          <ProductInfo
            currentProduct={this.state.currentProduct}
            showId={this.props.event.event.show_id}
            accountId={this.props.user.user.account_id} //{this.props.user.user.Account_Name.id}
            exhibitionId={this.props.event.event.exhibition_id}
            handleEditClose={this.handleEditClose.bind(this)}
            sidebar={this.props.sidebar}
            event={this.props.event.event}
            eventDetails={this.state.eventDetails}
            user={this.props.user.user}
            eventIsArchived={eventIsArchived}
            show={this.state.show}
          />
        );
      } else {
        if (this.state.loaded) {
          bodyContent = noResultsFound;
        } else {
          bodyContent = loadingGraphic;
        }
      }
    } else {
      bodyContent = (
        <Paper className={classes.paper}>
          <DataTable
            initialGridOptions={productGridOptions}
            initialRowData={this.state.products}
            actionButtons={ProductGridActionButtons}
            type="product"
            setDeleting={(e) => this.handleDeletingChange(e)}
            setEditing={(e) => this.handleEditingChange(e)}
            setCurrentPage={(e) => this.setCurrentProduct(e)}
            eventIsArchived={eventIsArchived}
          />
        </Paper>
      );
    }

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <Grid container>
                <Grid item xs={3}>
                  {this.state.editing ? (
                    <Button
                      onClick={() => this.handleEditingChange(false)}
                      variant="contained"
                      color="primary"
                    >
                      Back
                    </Button>
                  ) : null}
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    className={classes.center}
                    variant="h4"
                    color="textPrimary"
                  >
                    Products and Services
                  </Typography>
                  <Typography
                    className={classes.center}
                    variant="h6"
                    color={
                      this.state.products.length > this.state.maxProduct
                        ? "error"
                        : "textPrimary"
                    }
                  >
                    Items{" "}
                    {this.state.products.length
                      ? `${this.state.products.length}`
                      : `0`}
                    {this.state.maxProduct
                      ? ` / ${this.state.maxProduct}`
                      : ` / 0`}{" "}
                    Maximum
                  </Typography>
                  <Typography
                    className={classes.center}
                    variant="subtitle2"
                    color="textPrimary"
                  >
                    Please use the Booth Order field to rearrange your products
                    & services.
                  </Typography>
                  {eventIsArchived && (
                    <Typography
                      className={classes.center}
                      variant="body1"
                      color="error"
                    >
                      <br />
                      Event is archived and cannot be edited.
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  xs={3}
                  align="right"
                  style={{ marginTop: "auto", marginBottom: "auto" }}
                >
                  {!this.state.editing &&
                    this.state.products.length < this.state.maxProduct &&
                    !eventIsArchived && (
                      <Button
                        onClick={() => this.handleCreatingChange(true)}
                        variant="contained"
                        color="primary"
                      >
                        Add Product
                      </Button>
                    )}
                </Grid>
              </Grid>
            </Paper>
            {this.state.editing ? (
              <ProductInfo
                currentProduct={this.state.currentProduct}
                showId={this.props.event.event.show_id}
                exhibitionId={this.props.event.event.exhibition_id}
                accountId={this.props.user.user.account_id} //{this.props.user.user.Account_Name.id}
                handleEditClose={this.handleEditClose.bind(this)}
                sidebar={this.props.sidebar}
                event={this.props.event.event}
                eventDetails={this.state.eventDetails}
                user={this.props.user.user}
                eventIsArchived={eventIsArchived}
                show={this.state.show}
              />
            ) : (
              bodyContent
            )}
          </Grid>
        </Grid>
        <Dialog
          open={this.state.deleting}
          handleClose={() => this.handleDeletingCancel()}
        >
          {this.state.deleteInProcess ? (
            <Typography style={{ margin: "60px" }}>
              Deleting product...
            </Typography>
          ) : (
            <>
              <Typography>Are you sure you want to delete?</Typography>
              <br />
              <Button
                variant="contained"
                color="secondary"
                onClick={(e) => this.handleDeletingConfirm()}
              >
                Yes
              </Button>
              <br />
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.handleDeletingCancel()}
              >
                No
              </Button>
            </>
          )}
        </Dialog>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    event: state.event,
    sidebar: state.sidebar,
    archived: state.archived,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeAuthenticatedUser: removeAuthenticatedUser,
      removeEvent: removeEvent,
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(ProductsList));
