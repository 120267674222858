import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Dialog from "../../Components/Dialog";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import CheckIcon from "@material-ui/icons/Check";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";
import axios from "axios";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { ColorPicker } from "../Shared/ColorPicker";
import valuesToJson from "./valuesToJson";
import prepareRoutes from "./prepareRoutes";

// formik
import { Formik, Form, Field, useField, FieldArray } from "formik";

// Configure Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import setEvent from "../../Store/Actions/setEvent";
import setUser from "../../Store/Actions/setUser";
import removeAuthenticatedUser from "../../Store/Actions/removeUser";
import removeEvent from "../../Store/Actions/removeEvent";

import ForceLogOut from "../Shared/ForceLogOut";

const useStyles = (theme) => ({
  root: {
    marginBottom: theme.spacing(11),
  },
  header: {
    textAlign: "center",
  },
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  center: {
    textAlign: "center",
  },
  divider: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  submitGridItem: (props) => ({
    position: "fixed",
    bottom: "60px",
    left: props.sidebar?.open ? "120px" : "2px",
    width: "100%",
    zIndex: 1,
    textAlign: "center",
    background: theme.palette.background.main,
    transition: theme.transitions.create(["left"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    borderStyle: "solid",
    borderColor: theme.palette.background.dark,
  }),
  submitButton: (props) => ({
    color: "white",
    padding: theme.spacing(2, 8),
    marginLeft: theme.spacing(4),
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      boxShadow: "none",
    },
  }),
  gridFieldLeft: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    flexGrow: 0,
  },
  gridFieldRight: {
    flexGrow: 11,
  },
  toggleButtonGroup: {
    "& .Mui-selected": {
      color: "#FFF",
      backgroundColor: theme.palette.primary.main,
    },
    "& .Mui-selected:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  toggleButton: {
    height: "40px",
    width: "40px",
  },
  chipInputField: {
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "2px",
    },
  },
});

class EventConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventConfig: false,
      success: false,
    };
  }

  componentDidMount() {
    if (this.props.event.event !== null) {
      try {
        Auth.currentSession().then((data) => {
          axios({
            method: "get",
            url: "events/details/" + this.props.event.event.show_id,
            headers: { idtoken: data.idToken.jwtToken },
          }).then((response) => {
            // set up variables to assign to state
            let config = response.data;
            let availableRoutes = JSON.parse(config.available_routes).routes;
            let sponsors = JSON.parse(config.rotating_sponsors).sponsors;
            let topicMap = JSON.parse(config.topic_map);
            let preparedRoutes = prepareRoutes(availableRoutes);
            availableRoutes = preparedRoutes[0];
            let routesEnabled = preparedRoutes[1];
            let allowedRegistrationDomains = JSON.parse(
              config.allowed_registration_domains
            );
            let allowedSigninDomains = JSON.parse(
              config.allowed_signin_domains
            );
            let disableMobileStreaming = config.disable_mobile_streaming;
            let landingPageURL = config.landing_page_url;
            // let lobbyImageLocation = config.lobby_image_location; // TODO: USE THIS ONE?
            this.setState({
              eventConfig: true,
              availableRoutes: availableRoutes,
              routesEnabled: routesEnabled,
              sponsors: sponsors,
              topicMap: topicMap,
              title: config.title,
              landingPageContent: config.landing_page_content,
              mainColor: config.main_color,
              lightColor: config.light_color,
              welcomeVideoURL: config.welcome_video_location,
              iconLocation: config.icon_location,
              faviconLocation: config.favicon_location,
              faviconLocation192: config.favicon_location_192,
              faviconLocation512: config.favicon_location_512,
              bannerLocation: config.banner_location,
              agendaPrivate: config.agenda_private_location,
              agendaPublic: config.agenda_public_location,
              underConstruction: config.under_construction,
              twilioConversationId: config.twilio_conversation_id,
              twilioSyncSid: config.twilio_sync_sid,
              allowedRegistrationDomains,
              allowedSigninDomains,
              disableMobileStreaming,
              landingPageURL,
            });
          });
        });
      } catch (error) {
        if (error === "No current user") {
          console.log(error, "log them out");
          try {
            ForceLogOut(
              this.props.removeEvent,
              this.props.removeAuthenticatedUser
            );
          } catch (error) {
            console.log("ForceLogOut", error);
          }
        }
        console.log(error);
      }
    }
  }

  render() {
    const { classes } = this.props;

    if (this.props.event.event === null) {
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Paper className={classes.paper}>
                <Button component={Link} to={`/events`} color="secondary">
                  Select Event
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </div>
      );
    }

    const handleDialog = () => {
      this.setState({
        success: false,
      });
    };

    const LightenDarkenColor = (col, amt) => {
      var usePound = false;
      if (col[0] === "#") {
        col = col.slice(1);
        usePound = true;
      }

      var R = parseInt(col.substring(0, 2), 16);
      var G = parseInt(col.substring(2, 4), 16);
      var B = parseInt(col.substring(4, 6), 16);
      // to make the colour less bright than the input
      // change the following three "+" symbols to "-"
      R = R + amt;
      G = G + amt;
      B = B + amt;

      if (R > 255) R = 255;
      else if (R < 0) R = 0;

      if (G > 255) G = 255;
      else if (G < 0) G = 0;

      if (B > 255) B = 255;
      else if (B < 0) B = 0;

      var RR =
        R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
      var GG =
        G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
      var BB =
        B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

      return (usePound ? "#" : "") + RR + GG + BB;
    };

    let validateURL = (url) => {
      let error;
      const urlExpression =
        "^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$"; //eslint-disable-line
      let regex = new RegExp(urlExpression);
      if (!url) {
        // handle for case that url is null instead of ""
        url = "";
      }
      if (url !== "") {
        if (url.match(regex)) {
          error = "";
        } else {
          error = "Invalid URL";
        }
      }
      return error;
    };

    const ConfigInfoField = ({ ...props }) => {
      const [field, meta] = useField(props);
      if (field.value === null) {
        field.value = "";
      }

      return (
        <>
          <Field
            className="text-input"
            component={TextField}
            margin="dense"
            variant="outlined"
            fullWidth
            {...field}
            {...props}
          />
          {meta.touched && meta.error ? (
            <div
              className="error"
              style={{ color: "red", textAlign: "center" }}
            >
              {meta.error}
            </div>
          ) : null}
        </>
      );
    };

    const ConfigToggleField = ({ ...props }) => {
      const [field, meta, helpers] = useField(props);
      if (!field.value) {
        field.value = false;
      }
      const { setValue } = helpers;

      return (
        <>
          <Field
            className={classes.toggleButtonGroup}
            component={ToggleButtonGroup}
          >
            <ToggleButton
              className={classes.toggleButton}
              value="check"
              selected={field.value}
              {...field}
              {...props}
              onClick={() => setValue(!field.value)}
            >
              <CheckIcon fontSize="small" />
            </ToggleButton>
          </Field>
          {meta.touched && meta.error ? (
            <div className="error">{meta.error}</div>
          ) : null}
        </>
      );
    };

    const CompanyColorPicker = ({ ...props }) => {
      const [field, meta, helpers] = useField(props); //eslint-disable-line
      const { setValue } = helpers;
      if (field.value === null) {
        field.value = "";
      }
      return (
        <React.Fragment>
          <Field
            className="text-input"
            component={TextField}
            type="text"
            margin="dense"
            variant="outlined"
            fullWidth
            disabled
            {...field}
            {...props}
          />
          <ColorPicker
            color={field.value}
            onChange={(chosenColor) => {
              setValue(chosenColor.hex);
            }}
          />
        </React.Fragment>
      );
    };

    const DomainTagField = ({ ...props }) => {
      // const classes = useStyles();
      // const [tags, setTags] = React.useState([]);
      const [field, meta, helpers] = useField(props); //eslint-disable-line
      const { setValue } = helpers;

      let tags = [];
      tags = props.values[props.selected];

      // const handleOnKeyDown = (e) => {
      //   // if (e.keyCode === 13) {
      //   // props.values[props.selected] = [...tags, e.target.value];
      //   // setValue("");
      //   // }
      // };

      const onSubmit = (e) => {
        props.values[props.selected] = [...tags, field.value];
        setValue("");
      };

      return (
        <Box
          component="ul"
          style={{
            display: "flex",
            flexWrap: "wrap",
            listStyle: "none",
            padding: "2px",
            margin: 0,
            paddingRight: "0px",
          }}
        >
          <Typography
            variant="body1"
            color="textPrimary"
            style={{ margin: "auto" }}
          >
            {props.label}
          </Typography>
          <Field
            className={classes.chipInputField}
            component={TextField}
            // type="text"
            margin="dense"
            variant="outlined"
            fullWidth
            // onKeyDown={handleOnKeyDown}
            {...field}
            {...props}
            InputProps={{
              endAdornment: (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={(e) => onSubmit(e)}
                >
                  +Add
                </Button>
              ),
            }}
          />
        </Box>
      );
    };

    const DomainChips = ({ ...props }) => {
      // const classes = useStyles();
      const [field, meta, helpers] = useField(props); //eslint-disable-line
      const { setValue } = helpers;

      let tags = props.values[props.selected];

      const handleDelete = (e) => {
        tags = tags.filter((tag) => {
          return tag !== e;
        });
        props.values.tags = tags;
        setValue(tags);
      };

      let tagsMapped;
      if (tags) {
        tagsMapped = tags.map((data, index) => {
          return (
            <li key={index}>
              <Chip
                label={data}
                style={{ margin: "2px" }}
                color="primary"
                onDelete={() => handleDelete(data)}
                // onDelete={data === "React" ? undefined : handleDelete(data)} // working method from example: https://codesandbox.io/s/material-demo-forked-2uggu?file=/demo.js
              />
            </li>
          );
        });
      } else {
        tagsMapped = null;
      }

      return (
        <Box
          component="ul"
          style={{
            display: "flex",
            flexWrap: "wrap",
            listStyle: "none",
            padding: "2px",
            margin: 0,
          }}
        >
          {tagsMapped}
        </Box>
      );
    };

    const toggleInfoGrid = (
      fieldName,
      formalFieldName,
      fieldNumber,
      defaultName
    ) => {
      return (
        <Grid
          container
          style={{ display: "flex", alignItems: "center" }}
          key={formalFieldName}
        >
          <Grid item className={classes.gridFieldLeft}>
            <ConfigToggleField
              name={"routesEnabled." + fieldName}
              id={"routesEnabled." + fieldName}
            />
          </Grid>
          <Grid item className={classes.gridFieldRight}>
            <ConfigInfoField
              name={`routes[${fieldNumber}].title`}
              id={`routes[${fieldNumber}].title`}
              label={"Default : " + defaultName}
              placeholder={defaultName}
              type="text"
            />
          </Grid>
        </Grid>
      );
    };

    const singleSponsor = (number) => {
      return (
        <React.Fragment key={number}>
          <Divider className={classes.divider} />
          <Typography className={classes.header} variant="subtitle1">
            Sponsor {number + 1}
          </Typography>
          <ConfigInfoField
            name={`sponsors[${number}].title`}
            id={`sponsors[${number}].title`}
            label="Title"
            placeholder={`Title of Sponsor ${number + 1}`}
            type="text"
          />
          <ConfigInfoField
            name={`sponsors[${number}].level`}
            id={`sponsors[${number}].level`}
            label="Level"
            placeholder={`Level of Sponsor ${number + 1}`}
            type="text"
          />
          <ConfigInfoField
            name={`sponsors[${number}].logo`}
            id={`sponsors[${number}].logo`}
            label="Logo"
            placeholder={`Logo of Sponsor ${number + 1}`}
            type="text"
            multiline
            validate={validateURL}
          />
          <ConfigInfoField
            name={`sponsors[${number}].name`}
            id={`sponsors[${number}].name`}
            label="Name"
            placeholder={`Name of Sponsor ${number + 1}`}
            type="text"
            multiline
          />
        </React.Fragment>
      );
    };

    const mappedSponsors = [];

    if (this.state.eventConfig) {
      for (let i = 0; i < this.state.sponsors.length + 8; i++) {
        if (this.state.sponsors[i]) {
          let title = this.state.sponsors[i].title;
          let logo = this.state.sponsors[i].logo;
          let name = this.state.sponsors[i].name;
          let level = this.state.sponsors[i].level;
          let sponsorObject = {
            title: title,
            logo: logo,
            name: name,
            level: level,
          };
          mappedSponsors.push(sponsorObject);
        } else {
          let blankSponsor = {
            title: "",
            level: "",
            logo: "",
            name: "",
          };
          mappedSponsors.push(blankSponsor);
        }
      }
    }

    let singleTopic = (key, value, index) => {
      return (
        <React.Fragment key={index}>
          <Divider className={classes.divider} />
          <Typography className={classes.header} variant="subtitle1">
            Topic {index + 1}
          </Typography>
          {key === "Default" ? (
            <Typography
              className={classes.header}
              variant="subtitle2"
              color="textSecondary"
            >
              Default cannot be renamed
            </Typography>
          ) : null}
          <ConfigInfoField
            name={`topicMap[${index}].key`}
            id={`topicMap[${index}].key`}
            label="Name"
            placeholder="Name"
            type="text"
            disabled={key === "Default"}
          />
          <ConfigInfoField
            name={`topicMap[${index}].value`}
            id={`topicMap[${index}].value`}
            label="Photo URL"
            placeholder="Photo URL"
            type="text"
            multiline
            validate={validateURL}
          />
        </React.Fragment>
      );
    };

    const mappedTopics = [];

    if (this.state.eventConfig) {
      for (let topic in this.state.topicMap) {
        let key = topic;
        let value = this.state.topicMap[topic];
        mappedTopics.push({ key: key, value: value });
      }
      mappedTopics.push({ key: "", value: "" }, { key: "", value: "" });
      mappedTopics.push({ key: "", value: "" }, { key: "", value: "" });
      mappedTopics.push({ key: "", value: "" }, { key: "", value: "" });
      mappedTopics.push({ key: "", value: "" }, { key: "", value: "" });
      mappedTopics.push({ key: "", value: "" }, { key: "", value: "" });
      mappedTopics.push({ key: "", value: "" }, { key: "", value: "" });
      mappedTopics.push({ key: "", value: "" }, { key: "", value: "" });
      mappedTopics.push({ key: "", value: "" }, { key: "", value: "" });
    }

    let baseValues;
    let bodyContent;

    if (!this.state.eventConfig) {
      bodyContent = (
        <Typography className={classes.center} variant="h5">
          Event Config loading...
        </Typography>
      );
    } else {
      baseValues = {
        routes: this.state.availableRoutes,
        routesEnabled: this.state.routesEnabled,
        sponsors: mappedSponsors,
        topicMap: mappedTopics,
        title: this.state.title,
        landingPageContent: this.state.landingPageContent,
        mainColor: this.state.mainColor,
        lightColor: this.state.lightColor,
        welcomeVideoURL: this.state.welcomeVideoURL,
        iconLocation: this.state.iconLocation,
        faviconLocation: this.state.faviconLocation,
        faviconLocation192: this.state.faviconLocation192,
        faviconLocation512: this.state.faviconLocation512,
        bannerLocation: this.state.bannerLocation,
        agendaPrivate: this.state.agendaPrivate,
        agendaPublic: this.state.agendaPublic,
        underConstruction: this.state.underConstruction,
        twilioConversationId: this.state.twilioConversationId,
        twilioSyncSid: this.state.twilioSyncSid,
        allowedRegistrationDomains: this.state.allowedRegistrationDomains,
        currentRegistrationDomain: "",
        allowedSigninDomains: this.state.allowedSigninDomains,
        currentSigninDomain: "",
        disableMobileStreaming: this.state.disableMobileStreaming,
        landingPageURL: this.state.landingPageURL,
      };

      bodyContent = (
        <>
          {/* <Paper className={classes.paper}> */}
          <Formik
            initialValues={baseValues}
            onSubmit={async (values, { setSubmitting }) => {
              let light = LightenDarkenColor(values.mainColor, 40);
              values.lightColor = light;
              await new Promise((r) => setTimeout(r, 500));

              const newValues = valuesToJson(values);
              // CODE FOR SUBMITTING AXIOS REQUEST

              const user = await Auth.currentSession();

              const response = await axios({
                method: "PUT",
                url: "events/details/" + this.props.event.event.show_id,
                headers: { idtoken: user.idToken.jwtToken },
                data: {
                  newValues,
                },
              });

              // await wait(3000);

              if (response.data) {
                this.setState({ success: true });
              } else {
                alert("Your Event Config could not be updated");
              }
              // alert("This page is still under construction");
              setSubmitting(false);
            }}
          >
            {({ isSubmitting, values, handleChange }) => (
              <Form className={classes.form}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Paper className={classes.paper}>
                      <Typography className={classes.header} variant="h5">
                        Available Routes
                      </Typography>
                      <Divider className={classes.divider} />
                      <Typography
                        className={classes.header}
                        variant="subtitle1"
                      >
                        Toggle checkbox to enable/disable route.
                        <br />
                        Rename of the route is optional.
                      </Typography>
                      <Divider className={classes.divider} />
                      {this.state.availableRoutes.map((route, index) => {
                        let fieldName = route.default.replace(/\s/g, "");
                        let firstLetter = fieldName[0].toLowerCase(0);
                        let restOfFieldName = fieldName.slice(1);
                        let formattedFieldName = firstLetter + restOfFieldName;
                        let defaultName = route.default;
                        // console.log(formattedFieldName, route.title, index)
                        if (
                          route.title !== "Divider" &&
                          route.title !== "Debug"
                        ) {
                          return toggleInfoGrid(
                            formattedFieldName,
                            route.title,
                            index,
                            defaultName
                          );
                        } else {
                          return null;
                        }
                      })}
                    </Paper>
                    <Paper className={classes.paper}>
                      <Typography className={classes.header} variant="h5">
                        Authentication
                      </Typography>
                      <Divider className={classes.divider} />
                      <FieldArray
                        name="regTagFields"
                        render={() => {
                          return (
                            <>
                              <DomainTagField
                                name="currentRegistrationDomain"
                                id="currentRegistrationDomain"
                                label="Allowed Registration Domains"
                                selected="allowedRegistrationDomains"
                                values={values}
                                onFocus={this.tagFieldFocus}
                                onBlur={this.tagFieldBlur}
                              />
                              <DomainChips
                                name="allowedRegistrationDomains"
                                id="allowedRegistrationDomains"
                                label="Allowed Registration Domains"
                                selected="allowedRegistrationDomains"
                                values={values}
                              />
                            </>
                          );
                        }}
                      />
                      <FieldArray
                        name="signinTagFields"
                        render={() => {
                          return (
                            <>
                              <DomainTagField
                                name="currentSigninDomain"
                                id="currentSigninDomain"
                                label="Allowed Signin Domains"
                                selected="allowedSigninDomains"
                                values={values}
                                onFocus={this.tagFieldFocus}
                                onBlur={this.tagFieldBlur}
                              />
                              <DomainChips
                                name="allowedSigninDomains"
                                id="allowedSigninDomains"
                                label="Allowed Signin Domains"
                                selected="allowedSigninDomains"
                                values={values}
                              />
                            </>
                          );
                        }}
                      />
                    </Paper>
                    <Paper className={classes.paper}>
                      <Typography className={classes.header} variant="h5">
                        Sponsors
                      </Typography>
                      {mappedSponsors.map((sponsor, index) =>
                        singleSponsor(index)
                      )}
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Paper className={classes.paper}>
                      <Typography className={classes.header} variant="h5">
                        About
                      </Typography>
                      <Divider className={classes.divider} />
                      <ConfigInfoField
                        name="title"
                        id="title"
                        label="Short title of event that displays in browser tab"
                        placeholder="Event Title"
                        type="text"
                      />
                      {/* removed because strapi does this now */}
                      {/* <ConfigInfoField
                        name="landingPageContent"
                        id="landingPageContent"
                        label="Landing Page Content"
                        placeholder="Enter some information about your event here"
                        type="text"
                        rows={5}
                        maxRows={Infinity}
                        multiline
                      /> */}
                      <Divider className={classes.divider} />
                      <Typography
                        className={classes.header}
                        variant="subtitle1"
                      >
                        Icons
                      </Typography>
                      <Divider className={classes.divider} />
                      <ConfigInfoField
                        name="iconLocation"
                        id="iconLocation"
                        label="Square icon in top left location"
                        placeholder="https://www.iconurl.com"
                        type="text"
                        validate={validateURL}
                        multiline
                      />
                      <ConfigInfoField
                        name="faviconLocation"
                        id="faviconLocation"
                        label="Favicon64 that displays in browser tab"
                        placeholder="https://www.faviconurl.com"
                        type="text"
                        validate={validateURL}
                        multiline
                      />
                      <ConfigInfoField
                        name="faviconLocation192"
                        id="faviconLocation192"
                        label="Favicon192 used in PWA"
                        placeholder="https://www.faviconurl.com"
                        type="text"
                        validate={validateURL}
                        multiline
                      />
                      <ConfigInfoField
                        name="faviconLocation512"
                        id="faviconLocation512"
                        label="Favicon512 used in PWA"
                        placeholder="https://www.faviconurl.com"
                        type="text"
                        validate={validateURL}
                        multiline
                      />
                      <Divider className={classes.divider} />
                      <Typography
                        className={classes.header}
                        variant="subtitle1"
                      >
                        Twilio
                      </Typography>
                      <Divider className={classes.divider} />
                      <ConfigInfoField
                        name="twilioConversationId"
                        id="twilioConversationId"
                        label="Twilio Conversation ID"
                        placeholder="Twilio Conversation ID"
                        type="text"
                        multiline
                      />
                      <ConfigInfoField
                        name="twilioSyncSid"
                        id="twilioSyncSid"
                        label="Twilio Sync SID"
                        placeholder="Twilio Sync SID"
                        type="text"
                        multiline
                      />
                      <Divider className={classes.divider} />
                      <Typography
                        className={classes.header}
                        variant="subtitle1"
                      >
                        Supplementary Graphics
                      </Typography>
                      <Divider className={classes.divider} />
                      <ConfigInfoField
                        name="bannerLocation"
                        id="bannerLocation"
                        label="Banner used on landing page"
                        placeholder="https://www.bannerurl.com"
                        type="text"
                        validate={validateURL}
                        multiline
                      />
                      <ConfigInfoField
                        name="agendaPrivate"
                        id="agendaPrivate"
                        label="Private Agenda PDF URL"
                        placeholder="https://www.agendaprivateurl.com"
                        type="text"
                        validate={validateURL}
                        multiline
                      />
                      <ConfigInfoField
                        name="agendaPublic"
                        id="agendaPublic"
                        label="Public Agenda PDF URL"
                        placeholder="https://www.agendapublicurl.com"
                        type="text"
                        validate={validateURL}
                        multiline
                      />
                      <ConfigInfoField
                        name="welcomeVideoURL"
                        id="welcomeVideoURL"
                        label="Video URL of welcome video (if applicable)"
                        placeholder="https://player.vimeo.com"
                        type="text"
                        validate={validateURL}
                        multiline
                      />
                      <Divider className={classes.divider} />
                      <Typography
                        className={classes.header}
                        variant="subtitle1"
                      >
                        Environment Settings{" "}
                      </Typography>
                      <Divider className={classes.divider} />
                      <ConfigInfoField
                        name="landingPageURL"
                        id="landingPageURL"
                        label="Landing Page URL"
                        placeholder="Landing Page URL"
                        type="text"
                        multiline
                      />
                      <Grid
                        container
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Grid item className={classes.gridFieldLeft}>
                          <ConfigToggleField
                            name="underConstruction"
                            id="underConstruction"
                          />
                        </Grid>
                        <Grid item className={classes.gridFieldRight}>
                          <Typography variant="body2">
                            If box is checked, site is Under Construction
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Grid item className={classes.gridFieldLeft}>
                          <ConfigToggleField
                            name="disableMobileStreaming"
                            id="disableMobileStreaming"
                          />
                        </Grid>
                        <Grid item className={classes.gridFieldRight}>
                          <Typography variant="body2">
                            If box is checked, streaming is disabled on mobile
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                    <Paper className={classes.paper}>
                      <Typography className={classes.header} variant="h5">
                        Event Colors
                      </Typography>
                      <Divider className={classes.divider} />
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <CompanyColorPicker
                            name="mainColor"
                            id="mainColor"
                            label="Main Color"
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                    <Paper className={classes.paper}>
                      <Typography className={classes.header} variant="h5">
                        Topic Map
                      </Typography>
                      {mappedTopics.map((topic, index) =>
                        singleTopic(topic["key"], topic["value"], index)
                      )}
                    </Paper>
                  </Grid>
                </Grid>
                <Grid item xs={10} sm={12} className={classes.submitGridItem}>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    className={classes.submitButton}
                    color="primary"
                    variant="contained"
                    size="large"
                  >
                    <Typography variant="h6">
                      {isSubmitting ? "Saving..." : "Save Changes"}
                    </Typography>
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
          <Dialog open={this.state.success} handleClose={() => handleDialog()}>
            <Typography variant="h5">Success!</Typography>
            <Typography>Your Event Config has been updated.</Typography>
            <br />{" "}
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={() => handleDialog()}
            >
              X Close
            </Button>
          </Dialog>
          {/* </Paper> */}
        </>
      );
    }

    return (
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography
              className={classes.center}
              variant="h4"
              color="textPrimary"
            >
              Event Config
            </Typography>
          </Paper>
          {bodyContent}
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    event: state.event,
    sidebar: state.sidebar,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setEvent: setEvent,
      setUser: setUser,
      removeAuthenticatedUser: removeAuthenticatedUser,
      removeEvent: removeEvent,
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(EventConfig));
