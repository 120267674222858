import React, { Component } from "react";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Tabs, Tab } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import { AbstractInfo } from "./AbstractInfo";
import { AbstractGridActionButtons } from "./AbstractGridActionButtons";
import { abstractGridOptions } from "./abstractGridOptions";
import { DataTable } from "../Shared/DataTable";

// Configure Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import removeAuthenticatedUser from "../../Store/Actions/removeUser";
import removeEvent from "../../Store/Actions/removeEvent";

import ForceLogOut from "../Shared/ForceLogOut";
const useStyles = (theme) => ({
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  center: {
    textAlign: "center",
  },
  tabs: {
    // display: 'inline-flex',
    display: "flex",
    "& div.MuiTabs-scroller": {
      "& .MuiTabs-flexContainer": {
        justifyContent: "center",
      },
    },
    marginBottom: theme.spacing(1),
  },
  tabSingle: {
    minWidth: "auto",
  },
});

class AbstractsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      abstracts: [],
      editing: false,
      currentAbstract: null,
      loaded: false,
      tabValue: 0,
      categories: [],
    };
  }

  componentDidMount() {
    if (this.props.event.event !== null) {
      this.getAbstracts();
    }
  }

  getAbstracts() {
    try {
      Auth.currentSession().then((data) => {
        axios({
          method: "get",
          url: "/abstracts/admin/" + this.props.event.event.show_id,
          headers: { idtoken: data.idToken.jwtToken },
        })
          .then((response) => {
            const categories = [];
            for (let n = 0; n < response.data.data.length; n++) {
              if (!categories.includes(response.data.data[n].topic)) {
                categories.push(response.data.data[n].topic);
              }
            }
            this.setState({
              abstracts: response.data.data,
              categories,
            });
          })
          .catch((error) => {
            console.log(error);

            this.setState({
              abstracts: [],
              loaded: true,
            });
          });
      });
    } catch (error) {
      if (error === "No current user") {
        console.log(error, "log them out");
        try {
          ForceLogOut(
            this.props.removeEvent,
            this.props.removeAuthenticatedUser
          );
        } catch (error) {
          console.log("ForceLogOut", error);
        }
      }
      console.log(error);
    }
  }

  handleEditingChange(e) {
    this.setState({
      editing: e,
    });
  }

  setCurrentAbstract(e) {
    this.setState({
      currentAbstract: e,
    });
  }

  handleEditClose() {
    this.setState({
      editing: false,
      currentAbstract: null,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.timestamp === nextProps.timestamp) {
      return true;
    } else {
      this.setState({
        editing: false,
        currentAbstract: null,
      });
      return false;
    }
  }

  render() {
    const { classes } = this.props;

    if (this.props.event.event === null) {
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Paper className={classes.paper}>
                <Button component={Link} to={`/events`} color="secondary">
                  Select Event
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </div>
      );
    }

    let bodyContent;

    let loadingGraphic = (
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <br />
        <br />
        <CircularProgress color="inherit" />
        <Typography variant="h4" color="textPrimary">
          Loading...
        </Typography>
      </Grid>
    );
    let noResultsFound = (
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <br />
        <br />
        <Typography className={classes.center} variant="h5">
          You have no submitted abstracts for this event
        </Typography>
      </Grid>
    );

    let tabSelection = (
      <Tabs
        className={classes.tabs}
        value={this.state.tabValue}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
      >
        <Tab
          className={classes.tabSingle}
          label={"By Topic"}
          onClick={() => this.setState({ tabValue: 0 })}
        />
        <Tab
          className={classes.tabSingle}
          label={"All"}
          onClick={() => this.setState({ tabValue: 1 })}
        />
      </Tabs>
    );

    let abstractGridOptionsNoTopic = abstractGridOptions
      .map((option) => {
        if (option.id !== "topic") {
          return option;
        } else {
          return null;
        }
      })
      .filter((option) => option);

    if (this.state.abstracts.length === 0) {
      if (this.state.loaded) {
        bodyContent = noResultsFound;
      } else {
        bodyContent = loadingGraphic;
      }
    } else {
      if (this.state.tabValue === 0) {
        bodyContent = (
          <>
            {this.state.categories.map((category, index) => {
              return (
                <Paper className={classes.paper} key={`${category}${index}`}>
                  <br />
                  <Typography className={classes.center} variant="h5">
                    {category}
                  </Typography>
                  <br />
                  <br />
                  <DataTable
                    initialGridOptions={abstractGridOptionsNoTopic}
                    initialRowData={this.state.abstracts.filter(
                      (e) => e.topic === category
                    )}
                    actionButtons={AbstractGridActionButtons}
                    type="abstract"
                    setEditing={(e) => this.handleEditingChange(e)}
                    setCurrentPage={(e) => this.setCurrentAbstract(e)}
                    user={this.props.event.event}
                  />
                  <br />
                </Paper>
              );
            })}
          </>
        );
      } else {
        bodyContent = (
          <Paper className={classes.paper}>
            <DataTable
              initialGridOptions={abstractGridOptions}
              initialRowData={this.state.abstracts}
              actionButtons={AbstractGridActionButtons}
              type="abstract"
              setEditing={(e) => this.handleEditingChange(e)}
              setCurrentPage={(e) => this.setCurrentAbstract(e)}
              user={this.props.event.event}
            />
          </Paper>
        );
      }
    }

    return (
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <Grid container>
                <Grid item xs={3}>
                  {this.state.editing ? (
                    <Button
                      onClick={() => this.handleEditingChange(false)}
                      variant="contained"
                      color="primary"
                    >
                      Back
                    </Button>
                  ) : null}
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    className={classes.center}
                    variant="h4"
                    color="textPrimary"
                  >
                    All Abstracts
                  </Typography>
                </Grid>
                <Grid item xs={3} />
              </Grid>
            </Paper>
            {this.state.editing ? (
              <AbstractInfo
                currentAbstract={this.state.currentAbstract}
                getAbstracts={this.getAbstracts.bind(this)}
                handleEditClose={this.handleEditClose.bind(this)}
              />
            ) : (
              <React.Fragment>
                {tabSelection}
                {bodyContent}
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    event: state.event,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeAuthenticatedUser: removeAuthenticatedUser,
      removeEvent: removeEvent,
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(AbstractsList));
